import { useTranslation } from 'react-i18next';
import BarChartIcon from '@mui/icons-material/BarChart';

const AdminHeader = (props: {section: string}) => {
    const { t } = useTranslation();
    const { section } = props;

    return (
        <>
            <header className='c-admin__header o-container--full'>
                <div className='o-container'>
                    <h1 className="c-admin__title"><BarChartIcon className="c-admin__icon" /> { t('admin') }</h1>
                    { section && <h2 className="c-admin__section">{section}</h2> }
                </div>
            </header>
        </>
    );
}

export default AdminHeader;