import playIcon from '../../assets/icons/icon-play.svg';
import pauseIcon from '../../assets/icons/icon-pause.svg';
import { useTranslation } from 'react-i18next';
import { KeyboardEventHandler, MouseEventHandler } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface ControlsType {
    isPlaying: boolean
    onPlayPauseClick: Function
    currentPercentage: string
    trackProgress: number
    duration: string|number
    onScrub: Function
    onScrubEnd: Function
    onClose: Function
}

const Controls = ({isPlaying, onPlayPauseClick, currentPercentage, trackProgress, duration, onScrub, onScrubEnd, onClose}: ControlsType) => {
    const { t } = useTranslation();

    const trackStyling = `
        -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #0E6C67))
    `;

    const formatTime = (time: string|number) => {
        time = Number(time);
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time - minutes * 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    return (
        <div className="c-player__controls--buttons">
            {isPlaying
                ? (
                    <button type="button" className="c-player__controls--button" onClick={() => onPlayPauseClick(false)} aria-label={t("pause")}>
                        <img src={pauseIcon} alt={t("pause")} title={t("pause")} />
                    </button>
                )
                : (
                    <button type="button" className="c-player__controls--button" onClick={() => onPlayPauseClick(true)} aria-label={t("play")}>
                        <img src={playIcon} alt={t("play")} title={t("play")} />
                    </button>
                )
            }
            <div className="c-player__controls--progress">
                <input
                    type="range"
                    value={trackProgress}
                    step="0.1"
                    min="0"
                    max={duration}
                    className="c-player__controls--progress-bar"
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd as MouseEventHandler}
                    onKeyUp={onScrubEnd as KeyboardEventHandler}
                    style={{ background: trackStyling }}
                />
                <span className="c-player__controls--progress-current">{formatTime(trackProgress)}</span>
                <span className="c-player__controls--progress-duration">{formatTime(duration)}</span>
            </div>
            <div className="c-player__close">
                <button type="button" className="c-player__close-button" onClick={onClose as MouseEventHandler} aria-label={t("close")}>
                    <CloseIcon />
                </button>
            </div>
        </div>
    );
};
  
export default Controls;