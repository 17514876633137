import logo from '../../assets/logos/logo-home.svg';
import LoadingBar from '../../components/Landing/LoadingBar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Intro = () => {
    const handleClick = () => {
        window.scrollTo({
            top: window.innerHeight - 44,
            behavior: 'smooth'
        });
    }

    return (
        <>
            <aside className="c-home__intro">
                <img src={logo} className="c-home__intro--logo" alt="Logo Marta&David" title="Marta&David" />
                <LoadingBar />
                <KeyboardArrowDownIcon className="c-home__intro--arrow" onClick={handleClick} />
            </aside>
        </>
    )
}

export default Intro;