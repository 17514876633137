import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import playlistImage from '../../assets/images/playlist.png';
import HeadsetIcon from '@mui/icons-material/Headset';

const Playlist = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='c-home__playlist o-container--full'>
                <div className='o-container'>
                    <img src={playlistImage} className="c-home__playlist--image" alt={t('mas del sord')} title={t('mas del sord')} />
                    <div>
                        <h1 className="c-home__playlist--title"><span className="m-blob" />{t('new')}</h1>
                        <Link to='/music' className="c-home__playlist--button m-button"><HeadsetIcon /> {t('playlist')}</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Playlist;