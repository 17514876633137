import {MouseEventHandler, ReactNode} from "react";

interface ButtonType {
    children: ReactNode,
    type?: "primary"|"secondary"|"tertiary"|"danger",
    onClick?: Function,
    extraClass?: string
}

export const Button = ({children, onClick, type = "primary", extraClass = "", ...rest}: ButtonType) => {
    return <button
        className={"m-button m-button--" + type + (extraClass ? " " + extraClass : "")}
        onClick={onClick as MouseEventHandler}
        {...rest}
    >
        {children}
    </button>;
}

export default Button;