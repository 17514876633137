import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export interface UpdateUserDTO {
    user?: string
    phone?: string
    email?: string
    assistance?: boolean
    intolerances?: string[],
    otherIntolerances?: string
}

const getUsers = () => {
    return axios.get(API_URL + "/users");
};

const getAttendees = (assistance?: boolean) => {
    return axios.get(API_URL + "/users/attendees" + (assistance !== undefined ? "?assistance=" + assistance : ""));
};

const getGroupUsers = (groupId: string) => {
    return axios.get(API_URL + "/users/group/" + groupId);
};

const updateUser = (userId: string, data: UpdateUserDTO) => {
    const params = new URLSearchParams();
    if (data.phone) params.append('phone', data.phone);
    if (data.email) params.append('email', data.email);

    return axios.patch(API_URL + "/users/" + userId, params);
};

const confirmAssistance = (data: UpdateUserDTO) => {
    const params = new URLSearchParams();
    params.append('assistance', data.assistance + '');
    params.append('otherIntolerances', data.otherIntolerances + '');
    if (data.intolerances) params.append('intolerances', data.intolerances.join());

    return axios.patch(API_URL + "/users/" + data.user, params);
};

const UserService = {
    getUsers,
    getGroupUsers,
    getAttendees,
    updateUser,
    confirmAssistance
};

export default UserService;