import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export interface SearchTrackDTO {
    term: string;
}

export interface LoadTracksDTO {
    limit: number;
}

const getTracks = (data: LoadTracksDTO) => {
    return axios.get(API_URL + "/tracks?limit=" + data.limit);
};

const voteTrack = (trackId: string, userId: string) => {
    const params = new URLSearchParams();
    params.append('trackId', trackId);
    params.append('user', userId);

    return axios.post(API_URL + "/tracks", params);
};

const deleteVoteTrack = (trackId: string, userId: string) => {
    const params = new URLSearchParams();
    params.append('trackId', trackId);
    params.append('user', userId);

    return axios.put(API_URL + "/tracks", params);
};

const searchTracks = async (data: SearchTrackDTO) => {
    const response = await axios.get(API_URL + "/tracks/search", {
        params: {
            q: data.term,
        }
    });

    return response.data;
};

const MusicService = {
    getTracks,
    voteTrack,
    deleteVoteTrack,
    searchTracks
};

export default MusicService;