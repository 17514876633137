import '../../sass/home.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileModal from '../../components/Profile/ProfileModal';
import Header from '../../components/Header';
import Story from './Story';
import Location from './Location';
import Intro from './Intro';
import Footer from '../../components/Footer';
import Playlist from './Playlist';
import { User } from '../../domain/User/User';

const Home = () => {
    const [ openProfile, setOpenProfile ] = useState<boolean>(false);
    const [ showHeader, setShowHeader ] = useState<boolean>(false);
    const [ offset, setOffset ] = useState<number>(0);
    const isLoggedIn = useSelector((state:any) => state.auth.isLoggedIn as boolean);
    const showUserModal = useSelector((state:any) => state.auth.showUserModal as boolean);
    const user = useSelector((state:any) => state.auth.user as User);
    const navigate = useNavigate();

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        if (hash === '') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    useEffect(() => {
        if (!isLoggedIn){
            navigate('/login');
        }
        setOpenProfile(showUserModal);
    }, [showUserModal, isLoggedIn, navigate]);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        const logo = document?.querySelector('img.c-home__intro--logo')?.getBoundingClientRect();
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        const shouldShowMenu = (logo?.top as number) + (logo?.height as number) - (logo?.height as number)/2 < 0;
        if (shouldShowMenu && !showHeader) {
            setShowHeader(true);
        } else if (!shouldShowMenu && showHeader) {
            setShowHeader(false);
        }

        return () => window.removeEventListener('scroll', onScroll);
    }, [offset, showHeader]);

    return (
        <>
            {isLoggedIn && <>
                { openProfile &&
                    <ProfileModal
                        isModalVisible={true}
                        showModal={setOpenProfile}
                    />
                }
                <div className="c-home">
                    <Header showHeader={showHeader} />
                    <Intro />
                    <Location />
                    { user?.group?.name !== 'Convidats festa' && <Playlist /> }
                    <Story />
                    <Footer />
                </div>
            </>}
        </>
    )
}

export default Home;