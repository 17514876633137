import { Track } from "../../domain/Music/Track";
import playIcon from '../../assets/icons/icon-play.svg';
import Button from "../Button";
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from "react-i18next";

interface SearchTrackType {
    track: Track,
    votingTrack: string,
    userVoted: boolean,
    vote: Function,
    play: Function
}

const SearchTrack = ({track, votingTrack, userVoted, vote, play}: SearchTrackType) => {
    const { t } = useTranslation();
    const isLoading = votingTrack === track.trackId;

    return (
        <>
            <div className='c-music__track' key={track.trackId}>
                {track.images?.length &&
                    <div className="c-music__track--album" onClick={() => play(track)}>
                        <span className="c-music__track--votes"><StarIcon className='c-music__track--votes-icon' /> <span data-track-votes-id={track.trackId}>{track.votes.length}</span></span>
                        <img className='c-music__track--portrait' src={track.images.find(image => image.width === 300)?.url} alt={track.title} title={track.title} />
                        {track.preview && 
                            <>
                                <div className="c-music__track--play-overlay"></div>
                                <div className="c-music__track--play-icon">
                                    <img src={playIcon} alt={t("play")} title={t("play")}/>
                                </div>
                            </>
                        }
                    </div>
                }
                <Button extraClass={'c-music__track--vote' + (isLoading ? ' is-disabled is-loading' : '')} onClick={() => {vote(track)}}>
                    { userVoted ? <CheckIcon className="c-ranking__voted" /> : (isLoading ? '' : t('vote')) }
                </Button>
                <div className="c-music__track--info">
                    <p className="c-music__track--title">
                        <button type="button" title={track.title} className={!track.preview ? 'no-preview' : ''} onClick={() => play(track)}>{track.title}</button>
                    </p>
                    <p className="c-music__track--artists">{track.artist}</p>
                </div>
            </div>
        </>
    );
}

export default SearchTrack;