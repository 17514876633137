import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AdminTable from '../../components/Admin/AdminTable';
import AssistanceHead from './AssistanceHead';
import AdminHeader from '../../components/Admin/AdminHeader';
import { User } from '../../domain/User/User';
import UserService from '../../services/user.service';

const Assistance = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [attendeesUsers, setAttendeesUsers] = useState<User[]>([]);
    const [notAttendeesUsers, setNotAttendeesUsers] = useState<User[]>([]);
    const [notAnsweredAttendeesUsers, setNotAnsweredAttendeesUsers] = useState<User[]>([]);

    useEffect(() => {
        setAttendeesUsers([]);
        setNotAttendeesUsers([]);
        setNotAnsweredAttendeesUsers([]);
        UserService
        .getUsers()
        .then((response) => {
            response.data.forEach((user: User) => {
                if (user.assistance) {
                    setAttendeesUsers(attendeesUsers => [...attendeesUsers, user]);
                } else if (user.assistance === false) {
                    setNotAttendeesUsers(notAttendeesUsers => [...notAttendeesUsers, user]);
                } else {
                    setNotAnsweredAttendeesUsers(notAnsweredAttendeesUsers => [...notAnsweredAttendeesUsers, user]);
                }
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    return (
        <>
            <AdminHeader section={ t('assistance') } />
            <section className='c-admin__content o-container--full'>
                <div className='c-admin__users'>
                    <h2 className="c-admin__subtitle"><PersonAddAlt1Icon className='c-admin__subtitle-icon' />{t('confirmed')} ({attendeesUsers.length})</h2>
                    <AdminTable headCells={AssistanceHead} users={attendeesUsers} isLoading={loading} />
                </div>
                <div className='c-admin__users'>
                    <h2 className="c-admin__subtitle"><PersonRemoveIcon className='c-admin__subtitle-icon' />{t('declined')} ({notAttendeesUsers.length})</h2>
                    <AdminTable headCells={AssistanceHead} users={notAttendeesUsers} isLoading={loading} />
                </div>
                <div className='c-admin__users'>
                    <h2 className="c-admin__subtitle"><QuestionMarkIcon className='c-admin__subtitle-icon' />{t('notConfirmed')} ({notAnsweredAttendeesUsers.length})</h2>
                    <AdminTable headCells={AssistanceHead} users={notAnsweredAttendeesUsers} isLoading={loading} />
                </div>
            </section>
        </>
    );
}

export default Assistance;