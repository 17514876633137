import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeIcon from '@mui/icons-material/Home';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import HeadsetIcon from '@mui/icons-material/Headset';
import BarChartIcon from '@mui/icons-material/BarChart';
import LogoutIcon from '@mui/icons-material/Logout';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TerminalIcon from '@mui/icons-material/Terminal';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import logo from '../assets/logos/logo-header.svg';
import { logout } from "../actions/auth";
import { User } from "../domain/User/User";

interface HeaderType {
    showHeader?: boolean
}

export const Header = ({showHeader = false}: HeaderType) => {
    const [open, setOpen] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const user = useSelector((state:any) => state.auth.user as User);
    const currentLanguage = i18n.resolvedLanguage;
    const languages = ['ca', 'es'];
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            document.documentElement.className = 'no-scroll-mobile';
        } else {
            document.documentElement.className = '';
        }
    }, [open]);

    const handleClick = () => {
        setOpen(false);
    }

    const handleLogout = () => {
        dispatch(
            logout() as any
        )
        
        window.location.reload();
    }

    return (
        <>
            <header className={"c-menu c-menu--" + (showHeader ? 'visible' : 'hidden') + " c-menu--" + (open ? "open" : "closed")}>
                <div className="o-container">
                    {open && <MenuOpenIcon className="c-menu__button" onClick={() => {setOpen(!open);}} />}
                    {!open && <MenuIcon className="c-menu__button" onClick={() => {setOpen(!open);}} />}
                    <Link to='/' className="c-menu__logo"><img src={logo} alt="Logo Marta&David" title="Marta&David" className="c-menu__logo-image" /></Link>
                    <div className="c-menu__language">
                        {languages.map((language: string) => {
                            return <span
                            className={(currentLanguage === language ? 'm-language--selected' : '')}
                            key={language}
                            onClick={() => {
                                if (currentLanguage !== language) {
                                    i18n.changeLanguage(language);
                                }
                            }}
                            >{language}</span>
                        })}
                    </div>
                </div>
                <nav className="c-menu__nav">
                    <ul className={"c-menu__list" + (user.group.name === 'David i Marta' ? ' is-admin' : '') }>
                        <li className="c-menu__item">
                            <HomeIcon />
                            <Link className="c-menu__link" to='/' onClick={handleClick}>{t('home')}</Link>
                        </li>
                        <li className="c-menu__item">
                            <AutoStoriesIcon />
                            <Link className="c-menu__link" to={{ pathname: '/', hash: "#story" }} onClick={handleClick}>{t('story')}</Link>
                        </li>
                        { user.group.name !== 'Convidats festa' &&
                            <>
                                <li className="c-menu__item">
                                    <ThumbUpOffAltIcon />
                                    <Link className="c-menu__link" to='/rsvp' onClick={handleClick}>{t('rsvp')}</Link>
                                </li>
                            </>
                        }
                        <li className="c-menu__item">
                            <HeadsetIcon />
                            <Link className="c-menu__link" to='/music' onClick={handleClick}>{t('music')}</Link>
                        </li>
                        { user.group.name === 'David i Marta' &&
                            <li className="c-menu__item is-hacker">
                                <TerminalIcon />
                                <Link className="c-menu__link" to='/hacker' onClick={handleClick}>{t('hacker')}</Link>
                                <span className="m-blob" />
                            </li>
                        }
                        <li className="c-menu__item">
                            <LogoutIcon />
                            <span className="c-menu__link" onClick={handleLogout}>{ t("logout") }</span>
                        </li>
                        { user.group.name === 'David i Marta' &&
                            <>
                                <li className="c-menu__item c-menu__divider">
                                    <BarChartIcon />{t('admin')}
                                </li>
                                <li className="c-menu__item">
                                    <PersonAddAlt1Icon />
                                    <Link className="c-menu__link" to='/admin/assistance' onClick={handleClick}>{t('assistance')}</Link>
                                </li>
                                <li className="c-menu__item">
                                    <NoMealsIcon />
                                    <Link className="c-menu__link" to='/admin/intolerances' onClick={handleClick}>{t('intolerances')}</Link>
                                </li>
                                <li className="c-menu__item">
                                    <PlaylistAddCheckIcon />
                                    <Link className="c-menu__link" to='/admin/tracks' onClick={handleClick}>{t('music')}</Link>
                                </li>
                            </>
                        }
                    </ul>
                </nav>
                <div className="c-menu__out" onClick={() => setOpen(false)}></div>
            </header>
        </>
    );
}

export default Header;