import InputIntolerance from '../../components/Rsvp/InputIntolerance';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../domain/User/User";
import { Intolerance } from '../../domain/Intolerance/Intolerance';
import Button from '../Button';
import { confirmAssistance, refreshAttendees } from '../../actions/user';
import { UpdateUserDTO } from "../../services/user.service";
import Input from '../Input';

interface RsvpFormType {
    user: User,
    intolerances: Intolerance[],
    self: boolean
}

export const RsvpForm = ({user, self, intolerances}: RsvpFormType) => {
    const { t } = useTranslation();
    const [ isLoading, setLoading ] = useState<boolean>(false);
    const [ userIntolerances, setUserIntolerances ] = useState<Intolerance[]>(user.intolerances ? user.intolerances : []);
    const [ userOtherIntolerances, setUserOtherIntolerances ] = useState<string>(user.otherIntolerances ? user.otherIntolerances : '');
    const [ assistance, setAssistance ] = useState<boolean>(user.assistance);
    const [ assistanceSelected, setAssistanceSelected ] = useState<boolean>(user.assistance !== null);
    const [ confirmationMessage, setConfirmationMessage ] = useState<string>('');
    const dispatch = useDispatch();

    const userHasIntolerance = (intolerance: Intolerance): boolean => {
        return userIntolerances.find((userIntolerance: Intolerance) => userIntolerance.id === intolerance.id) !== undefined;
    };

    const handleAssistance = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAssistance(event.target.value === "true");
        setAssistanceSelected(true);
    }

    const handleIntolerance = (event: React.ChangeEvent<HTMLInputElement>) => {
        const intolerance = intolerances.find((intolerance: Intolerance) => intolerance.id === event.target.value);
        if (intolerance) {
            if (userHasIntolerance(intolerance)) {
                setUserIntolerances(userIntolerances.filter((userIntolerance: Intolerance) => userIntolerance.id !== intolerance.id));
            } else {
                setUserIntolerances([...userIntolerances, intolerance]);
            }
        }
    }

    const handleOtherIntolerances = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserOtherIntolerances(event.target.value);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        const user = formData.get('user') as string;
        const assistance = formData.get('assistance') as string;
        const intolerances = assistance === 'false' ? [] : formData.getAll('intolerances');
        const otherIntolerances = assistance === 'false' ? '' : formData.get('other') as string;

        setLoading(true);
        const DTO = {
            user: user,
            assistance: assistance === 'true' ? true : false,
            intolerances: intolerances,
            otherIntolerances: otherIntolerances
        } as UpdateUserDTO;
        dispatch(
            confirmAssistance(DTO) as any
        )
        .then(() => {
            setConfirmationMessage(t('confirmation ' + assistance));
            if (assistance === 'false') {
                setUserIntolerances([]);
                setUserOtherIntolerances('');
            }
            setLoading(false);
        })
        .finally(() => {
            dispatch(
                refreshAttendees() as any
            )
        })
    };

    return (
        <>
            <div className={"c-rsvp__user" + (self ? ' c-rsvp__user-me' : '') } key={user.id}>
                <div className="c-rsvp__user-name">
                    {user.fullName}
                </div>

                <form className={"c-form" + (isLoading ? ' is-loading' : '')} onSubmit={handleSubmit} data-user-id={user.id}>
                    <FormGroup>
                        <div className="c-rsvp__confirmation">
                            <span className="c-rsvp__confirmation-title">{ t(self ? "confirmation" : "confirmation other") }</span>
                            <FormControlLabel
                                value={true}
                                control={<Switch className='c-rsvp__confirmation-input' checked={assistanceSelected && assistance} onChange={handleAssistance} />}
                                label={t("yes")}
                                labelPlacement="end"
                                name='assistance'
                                key={user.id+'-yes'}
                            />
                            <FormControlLabel
                                value={false}
                                control={<Switch className='c-rsvp__confirmation-input' checked={assistanceSelected && !assistance} onChange={handleAssistance} />}
                                label={t("no")}
                                labelPlacement="end"
                                name='assistance'
                                key={user.id+'-no'}
                            />
                        </div>
                        
                        { user.age !== 'BABY' &&
                            <div className={"c-rsvp__intolerances" + (assistance ? '' : ' hide') }>
                                <span className="c-rsvp__intolerances-title">{ t("intolerances") }:</span>
                                { intolerances && (intolerances.map((intolerance: Intolerance) => {
                                    return (
                                        <InputIntolerance
                                            key={intolerance.id}
                                            intolerance={intolerance}
                                            self={self}
                                            gender={user.gender}
                                            checked={userHasIntolerance(intolerance)}
                                            onChange={handleIntolerance}
                                        />
                                    );
                                }))}
                                <div className="c-rsvp__intolerance-other">
                                    <Input label={t("other intolerances")} id={"other-" + user.id} name="other" type="text" value={userOtherIntolerances} onChange={handleOtherIntolerances} />
                                </div>
                            </div>
                        }
                        <input type="hidden" name="user" value={user.id} />
                        <Button type='primary' extraClass={"c-form__submit" + (!assistanceSelected ? ' is-disabled' : '')}>{ t("confirm") }</Button>
                        { confirmationMessage && <div className="c-rsvp__feedback">{ confirmationMessage }</div> }
                    </FormGroup>
                </form>
            </div>
        </>
    )
}

export default RsvpForm;