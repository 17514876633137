import { useTranslation } from "react-i18next";
import { Modal, ModalType } from "../Modal";
import LoginForm from "./LoginForm";

const LoginModal = ({isModalVisible, showModal}: ModalType) => {
    const { t } = useTranslation();

    return (
        <Modal
            isModalVisible={isModalVisible}
            showModal={showModal}
            title={t("login title")}
            body={<LoginForm />}
        />
    );
};

export default LoginModal;