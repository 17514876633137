import m from '../../assets/images/hacker/m.jpg';
import a from '../../assets/images/hacker/a.jpg';
import r from '../../assets/images/hacker/r.jpg';
import t from '../../assets/images/hacker/t.jpg';
import d from '../../assets/images/hacker/d.jpg';
import v from '../../assets/images/hacker/v.jpg';
import i from '../../assets/images/hacker/i.jpg';
import punticoma from '../../assets/images/hacker/punticoma.png';
import other from '../../assets/images/hacker/other.jpg';

const HackerElement = (props: {letter: string}) => {
    const { letter } = props;
    let image = other;
    switch (letter) {
        case 'm':
            image = m;
            break;
        case 'a':
            image = a;
            break;
        case 'r':
            image = r;
            break;
        case 't':
            image = t;
            break;
        case 'd':
            image = d;
            break;
        case 'v':
            image = v;
            break;
        case 'i':
            image = i;
            break;
        case ';':
            image = punticoma;
            break;
    }

    return (
        <img src={image} alt={letter} className='c-hacker__item' />
    );
}

export default HackerElement;