import '../../sass/admin.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { User } from '../../domain/User/User';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Assistance from '../../components/Admin/Assistance';
import Intolerances from '../../components/Admin/Intolerances';
import Tracks from '../../components/Admin/Tracks';

const Admin = () => {
    const isLoggedIn = useSelector((state:any) => state.auth.isLoggedIn as boolean);
    const user = useSelector((state:any) => state.auth.user as User);
    const navigate = useNavigate();
    const { section } = useParams();

    useEffect(() => {
        const isAdminUser = isLoggedIn && user.group.name === 'David i Marta';
        if (!isAdminUser){
            navigate('/login');
        }
    }, [isLoggedIn, user, navigate]);

    return (
        <>
            {isLoggedIn && 
                <div className="c-admin">
                    <Header showHeader={true} />
                    { section === 'assistance' && <Assistance /> }
                    { section === 'intolerances' && <Intolerances /> }
                    { section === 'tracks' && <Tracks /> }
                    <Footer />
                </div>
            }
        </>
    )
}

export default Admin;