import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import AdminTable from '../../components/Admin/AdminTable';
import AdminHeader from '../../components/Admin/AdminHeader';
import { User } from '../../domain/User/User';
import UserService from '../../services/user.service';
import IntoleranceHead from './IntoleranceHead';

const Intolerances = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        setUsers([]);
        UserService
        .getUsers()
        .then((response) => {
            response.data.forEach((user: User) => {
                if (user.otherIntolerances !== '' || user.intolerances.length > 0) {
                    setUsers(users => [...users, user]);
                }
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    return (
        <>
            <AdminHeader section={ t('intolerances') } />
            <section className='c-admin__content o-container--full'>
                <div className='c-admin__users'>
                    <h2 className="c-admin__subtitle"><NoMealsIcon className='c-admin__subtitle-icon' />{t('attendees')} ({users.length})</h2>
                    <AdminTable headCells={IntoleranceHead} users={users} isLoading={loading} />
                </div>
            </section>
        </>
    );
}

export default Intolerances;