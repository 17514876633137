import '../../sass/music.scss';
import musicImage from '../../assets/images/music.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import MusicService from '../../services/music.service';
import { deleteVoteTrack, voteTrack } from '../../actions/user';
import { Track } from '../../domain/Music/Track';
import Input from '../../components/Input';
import Button from '../../components/Button';
import SearchIcon from '@mui/icons-material/Search';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { User } from '../../domain/User/User';
import RankingTrack from '../../components/Music/RankingTrack';
import SearchTrack from '../../components/Music/SearchTrack';
import Player from '../../components/Music/Player';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Music = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state:any) => state.auth.isLoggedIn as boolean);
    const user = useSelector((state:any) => state.auth.user as User);
    const navigate = useNavigate();
    const [searchKey, setSearchKey] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searching, setSearching] = useState(false);
    const [refreshRanking, setRefreshRanking] = useState(true);
    const [votingTrack, setVotingTrack] = useState<string>('');
    const [rankingTracks, setRankingTracks] = useState<Track[]>([]);
    const [totalTracks, setTotalTracks] = useState<string>("0");
    const [searchResults, setSearchResults] = useState<Track[]>([]);
    const [page, setPage] = useState<number>(2);
    const [playingTrack, setPlayingTrack] = useState<Track|null>(null);

    const searchTracks = async (e: any) => {
        e.preventDefault();
        if (searchKey.length > 0) {
            setSearching(true);
            MusicService
                .searchTracks({
                    term: searchKey
                })
                .then((data) => {
                    setSearchResults(data);
                    setSearching(false);
                }
            );
        }
    }

    const vote = (track: Track) => {
        setVotingTrack(track.trackId);

        if (userVoted(track.trackId)) {
            dispatch(deleteVoteTrack(track.trackId, user) as any)
            .then(() => {
                document.querySelectorAll('[data-track-votes-id="' + track.trackId + '"]').forEach((element: any) => {
                    element.innerHTML = parseInt(element.textContent) - 1;
                });
                setVotingTrack('');
                setRefreshRanking(true);
            });
        } else {
            dispatch(voteTrack(track.trackId, user) as any)
            .then(() => {
                document.querySelectorAll('[data-track-votes-id="' + track.trackId + '"]').forEach((element: any) => {
                    element.innerHTML = parseInt(element.textContent) + 1;
                });
                setVotingTrack('');
                setRefreshRanking(true);
            });    
        }
    }

    const userVoted = (trackId: string) => {
        return user.votes.includes(trackId);
    }

    const playTrack = (track: Track) => {
        if (track.preview) {
            setPlayingTrack(track);
            document.getElementsByClassName('c-music')[0].classList.add('is-playing');
        }
    }

    const scrollToRanking = () => {
        const ranking = document.getElementsByClassName('c-music__content')[0];
        if (ranking) {
            ranking.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        if (loadingMore) {
            MusicService
                .getTracks({
                    limit: page*12
                })
                .then((response) => {
                    setRankingTracks(response.data.tracks);
                    setTotalTracks(response.data.total);
                    setLoadingMore(false);
                    setPage(page+1);
                }
            );
        }
    }, [loadingMore, page, rankingTracks]);

    useEffect(() => {
        if (refreshRanking) {
            if (rankingTracks.length === 0) {
                setLoading(true);
            }
            MusicService
                .getTracks({
                    limit: Math.max(rankingTracks.length, 12)
                })
                .then((response) => {
                    setRankingTracks(response.data.tracks);
                    setTotalTracks(response.data.total);
                    setLoading(false);
                    setRefreshRanking(false);
                }
            );
        }
    }, [refreshRanking, rankingTracks.length]);

    useEffect(() => {        
        if (!isLoggedIn){
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    return (
        <>
            {isLoggedIn &&
                <div className="c-music">
                    <Header />
                    <header className='c-music__header o-container--full'>
                        <img className='c-music__image' src={musicImage} alt="logo" />
                        <div className='c-music__header--info'>
                            <h1 className="c-music__title">
                                <span>{t('music title 1')}</span>
                                <span className='c-music__title-2'>{t('music title 2')}</span>
                            </h1>
                            <p className='c-music__description'>{t('music description')}</p>
                            <p className='c-music__description'>{t('music description 2')}</p>
                            <Button extraClass='c-music__description--ranking' onClick={scrollToRanking} type='primary'><EmojiEventsIcon /> {t('go to ranking')}</Button>
                        </div>
                    </header>
                    <form className="c-music__search" onSubmit={searchTracks}>
                        <Input extraClass='c-music__input' id='search' type='text' value={searchKey} onChange={(e: any) => setSearchKey(e.target.value)} label={t('search')} />
                        <Button extraClass='c-music__submit' type='primary'><SearchIcon /> {t('search')}</Button>
                    </form>
                    <section className={'c-music__content o-container--full' + (searching ? ' is-loading' : '') + (searchResults.length === 0 ? ' is-empty' : '')}>
                        { searchResults && searchResults.length > 0 &&
                            <div className='c-music__tracks'>
                                {searchResults.map((track: Track) => (
                                    <SearchTrack
                                        key={track.trackId}
                                        track={track}
                                        votingTrack={votingTrack}
                                        userVoted={userVoted(track.trackId)}
                                        vote={vote}
                                        play={playTrack}
                                    />
                                ))}
                            </div>
                        }
                    </section>
                    <section className="c-ranking__content o-container--full">
                        <div className="c-ranking">
                            <div className="c-ranking__title">
                                <EmojiEventsIcon className="c-ranking__title-icon" />
                                <h2>{t('ranking top title')}</h2>
                                <p className="c-ranking__total">{t('ranking top subtitle').replace("{number}", totalTracks)}</p>
                            </div>
                            <div className={"c-ranking__tracks" + (loading ? ' is-loading' : '')}>
                                {rankingTracks && rankingTracks.map((track: Track, index) => (
                                    <RankingTrack
                                        key={track.trackId}
                                        track={track}
                                        votingTrack={votingTrack}
                                        userVoted={userVoted(track.trackId)}
                                        ranking={index+1}
                                        vote={vote}
                                        play={playTrack}
                                    />
                                ))}
                                {((page-1)*12 < parseInt(totalTracks)) && <Button extraClass={'c-ranking__more' + (loadingMore ? ' is-loading' : '')} type='primary' onClick={() => setLoadingMore(true)}><ExpandMoreIcon /> {t('ranking more')}</Button>}
                            </div>
                        </div>
                    </section>
                    <Footer />
                    {playingTrack && <Player track={playingTrack}/>}
                </div>
            }
        </>
    )
};

export default Music;