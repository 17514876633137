import { useState } from "react";
import { useTranslation } from "react-i18next";

export const ChangeLanguage = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const languages = i18n.languages;
    const [open, setOpen] = useState<boolean>(false);

    return (
        <div
            className={"m-language m-language--" + (open ? "open" : "closed")}
            onMouseLeave={() => setOpen(false)}
            onMouseEnter={() => setOpen(true)}
            onClick={() => setOpen(!open)}
        >
            {languages.map((language: string) => {
                return <button
                    className={"m-button" + (currentLanguage === language ? ' m-language--selected' : '')}
                    key={language}
                    onClick={() => {
                        if (currentLanguage !== language) {
                            i18n.changeLanguage(language);
                        }
                        setOpen(false);
                    }}
                >{language}</button>
            })}
        </div>
    );
}

export default ChangeLanguage;