import '../../sass/hacker.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { User } from '../../domain/User/User';
import { useTranslation } from 'react-i18next';
import HackerElement from '../../components/Hacker/HackerElement';
import Modal from '../../components/Modal';
import regalanticipat2017 from '../../assets/images/hacker/regalanticipat2017.jpg';
import regal30anys from '../../assets/images/hacker/regal30anys.jpg';

const Hacker = () => {
    const { t } = useTranslation();
    const isLoggedIn = useSelector((state:any) => state.auth.isLoggedIn as boolean);
    const user = useSelector((state:any) => state.auth.user as User);
    const navigate = useNavigate();
    const [text, setText] = useState<string>('');
    const [modalMessage, setModalMessage] = useState<string>('');
    const [modalSubMessage, setModalSubMessage] = useState<string>('');
    const [modalImage, setModalImage] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [elements, setElements] = useState<string[]>([]);

    useEffect(() => {
        const isAdminUser = isLoggedIn && user.group.name === 'David i Marta';
        if (!isAdminUser){
            navigate('/login');
        }
    }, [isLoggedIn, user, navigate]);

    useEffect(() => {
        if (['nadal2017', 'regalanticipat2017', 'regal30anys'].includes(text)) {
            setShowModal(true);
            setModalSubMessage('');
            setModalMessage(t(text));
            setModalImage('');
            setText('');
            if (text === 'regalanticipat2017') {
                setModalImage(regalanticipat2017);
            } else if (text === 'regal30anys') {
                setModalImage(regal30anys);
                setModalSubMessage(t('regal30anysSub'));
            }
        } else {
            const letters = [] as string[];
            setElements(letters);
            text.split('').forEach((letter: string) => {
                if (letter !== ' ') {
                    letters.push(letter);
                }
                setElements(letters);
            });
        }
    }, [text, t]);

    return (
        <>
            {isLoggedIn &&
                <div className="c-hacker">
                    <>
                        <Header />
                        <input type="text" className='c-hacker__input' placeholder={t('writeHere')} onChange={(e) => {setText(e.target.value)}} />
                        <div className='c-hacker__result'>
                            {
                                showModal
                                ? <Modal
                                    isModalVisible={true}
                                    showModal={setShowModal}
                                    title={t("surprise")}
                                    body={<div>
                                        {modalMessage && <p>{modalMessage}</p>}
                                        {modalImage && <img className="c-hacker__modal-img" src={modalImage} alt="" />}
                                        {modalSubMessage && <p>{modalSubMessage}</p>}
                                    </div>}
                                />
                                : elements.map((letter: string, index) => <HackerElement letter={letter} key={index} />)
                            }
                        </div>
                    </>
                </div>
            }
        </>
    )
}

export default Hacker;