import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { User } from '../../domain/User/User';
import { useSelector } from 'react-redux';

const Story = () => {
    const { t } = useTranslation();
    const user = useSelector((state:any) => state.auth.user as User);

    return (
        <>
            <section className='c-home__story o-container--full' id='story'>
                <div className='o-container'>
                    <h2 className="c-home__title">{ t("story") }</h2>
                </div>
                {function() {
                    let content = [];
                    for (let i = 0; i <= 10; i++){
                        const isOdd = i%2 === 1;
                        content.push( 
                            <div className={"c-home__chapter c-home__chapter-" + i + (isOdd ? ' c-home__chapter-odd' : '')} key={"chapter-"+i}>
                                { (i > 0) &&
                                    <div className="c-home__chapter-image" style={{backgroundImage: 'url("' + require('../../assets/images/chapter-'+i+'.jpg') + '")' }} />
                                }
                                <div className='o-container'>
                                    <h3 className="c-home__chapter-title">{ t("chapter "+i+" title") }</h3>
                                    <div className='c-home__chapter-content' dangerouslySetInnerHTML={{ __html: t("chapter "+i+" content") }} />
                                    { ( i === 10 && user?.group?.name !== 'Convidats festa' ) && 
                                        <Link to='/rsvp' className="c-home__rsvp-button m-button m-button--primary">{ t("rsvp") } <ArrowForwardIosIcon /></Link>
                                    }
                                    { ( i === 10 && user?.group?.name === 'Convidats festa' ) && 
                                        <Link to='/music' className="c-home__rsvp-button m-button m-button--primary">{ t("playlist") + " a la " + t("music") } <ArrowForwardIosIcon /></Link>
                                    }
                                    { ( i > 0 ) && <span className='c-home__chapter-page'>- {i}/10 -</span>}
                                </div>
                            </div>
                        );
                    }
                    return content;
                }()}
            </section>
        </>
    )
}

export default Story;