import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getIntolerances = () => {
    return axios.get(API_URL + "/intolerances");
};

const IntoleranceService = {
    getIntolerances
};

export default IntoleranceService;