import { useEffect, useRef, useState } from "react";
import { Track } from "../../domain/Music/Track";
import Controls from "./Controls";

interface PlayerType {
    track: Track
}

const Player = ({track}: PlayerType) => {
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef(new Audio(track?.preview));
    const intervalRef = useRef() as any;
    const isReady = useRef(true);

    const { duration } = audioRef.current;

    const currentPercentage = duration
        ? `${(trackProgress / duration) * 100}%`
        : "0%";

    const startTimer = () => {
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                setIsPlaying(false);
                setTrackProgress(0);
            } else {
                setTrackProgress(audioRef.current.currentTime);
            }
        }, 100);
    };

    const onScrub = (value: any) => {
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    };

    const onScrubEnd = () => {
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    };

    const onClose = () => {
        clearInterval(intervalRef.current);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setTrackProgress(0);
        setIsPlaying(false);
        document.getElementsByClassName('c-music')[0]?.classList.remove('is-playing');
    }

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
            startTimer();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        if (audioRef.current.readyState === 4) {
            audioRef.current.pause();
        }
    
        audioRef.current = new Audio(track?.preview);
        setTrackProgress(audioRef.current.currentTime);
    
        if (isReady.current) {
            audioRef.current.play();
            setIsPlaying(true);
            startTimer();
        } else {
            isReady.current = true;
        }
    }, [track]);

    useEffect(() => {
        return () => {
            onClose();
        }
    }, []);
  
    return (
        <>
            <div className="c-player">
                <div className="c-player__track-info">
                    <img className="c-player__image" src={track?.images.find(image => image.width === 64)?.url} alt={track?.title} title={track?.title} />
                    <div className="c-player__info-text">
                        <h2 className="c-player__title">{track?.title}</h2>
                        <h3 className="c-player__artist">{track?.artist}</h3>
                    </div>
                </div>
                <div className="c-player__controls">
                    <Controls
                        isPlaying={isPlaying}
                        onPlayPauseClick={setIsPlaying}
                        currentPercentage={currentPercentage}
                        trackProgress={trackProgress}
                        duration={duration ? duration : `${duration}`}
                        onScrub={onScrub}
                        onScrubEnd={onScrubEnd}
                        onClose={onClose}
                    />
                    <div className="c-player__controls--background" style={{backgroundImage: "url('" + track?.images.find(image => image.width === 64)?.url + "')"}} />
                </div>
            </div>
        </>
    );
}

export default Player;