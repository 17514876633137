import { HeadCell } from "./AdminTable";

export const AssistanceHead: HeadCell[] = [
    {
        id: 'fullName',
        align: 'left',
    },
    {
        id: 'group',
        align: 'left',
    },
    {
        id: 'phone',
        align: 'center',
    },
    {
        id: 'email',
        align: 'left',
    },
    {
        id: 'connectionTimes',
        align: 'center',
    },
    {
        id: 'lastConnection',
        align: 'right',
    }
];

export default AssistanceHead;