import logo from '../assets/logos/logo-home.svg';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { User } from '../domain/User/User';
import SavingsIcon from '@mui/icons-material/Savings';

export const Footer = () => {
    const { t } = useTranslation();
    const user = useSelector((state: any) => state.auth.user as User);

    return (
        <>
            <footer className="c-footer o-container--full">
                <div className="o-container">
                    <div className="c-footer__name">
                        <p>Marta<br /><span className='c-footer__phone'>636 335 785</span></p>
                    </div>
                    <img src={logo} alt="Logo Marta&David" title="Marta&David" className="c-footer__logo" />
                    <div className="c-footer__name">
                        <p>David<br /><span className='c-footer__phone'>656 379 271</span></p>
                    </div>
                    {user?.group?.name !== 'Convidats festa' &&
                        <div className="c-footer__iban">
                            {
                                user.fullName === 'Arnau Olesti'
                                ? <p><SavingsIcon /> {t('iban_no')}</p>
                                : <p><SavingsIcon /> ES09 1465 0100 98 1730287562</p>
                            }
                        </div>
                    }
                </div>
            </footer>
        </>
    );
}

export default Footer;