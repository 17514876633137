import { HeadCell } from "./AdminTable";

export const IntoleranceHead: HeadCell[] = [
    {
        id: 'fullName',
        align: 'left',
    },
    {
        id: 'group',
        align: 'left',
    },
    {
        id: 'intolerances',
        align: 'left',
    },
    {
        id: 'otherIntolerances',
        align: 'left',
    }
];

export default IntoleranceHead;