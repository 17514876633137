import { MouseEvent, useState } from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from "react-i18next";
import { Track } from "../../domain/Music/Track";

type Order = 'asc' | 'desc';

export interface TracksHeadCell {
    id: keyof Track;
    align: "left" | "inherit" | "center" | "right" | "justify" | undefined;
}

export interface TracksAdminTableProps {
    onRequestSort: (event: MouseEvent<unknown>, property: keyof Track) => void;
    order: Order;
    orderBy: string;
    headCells: TracksHeadCell[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, order: string) {
    if (a[orderBy] === null || a[orderBy] + '' === '') {
        return order === 'asc' ? -1 : 1;
    }

    if (b[orderBy] === null || b[orderBy] + '' === '') {
        return order === 'asc' ? 1 : -1;
    }

    if (orderBy === 'user') {
        var userA = a[orderBy] as any;
        var userB = b[orderBy] as any;

        if (userA.name === userB.name) {
            return 0;
        }

        return userA.name < userB.name ? 1 : -1;
    }

    if (orderBy === 'votes') {
        var userVoteA = a[orderBy] as any;
        var userVoteB = b[orderBy] as any;

        var userAString = userVoteA.sort((userA: any, userB: any) => (userA.name < userB.name) ? -1 : 1).map((user: any) => user.name).join('');
        var userBString = userVoteB.sort((userA: any, userB: any) => (userA.name < userB.name) ? -1 : 1).map((user: any) => user.name).join('');
        
        if (userAString === userBString) {
            return 0;
        }

        return userAString < userBString ? 1 : -1;
    }

    if (orderBy === 'createdAt') {
        const createdAtA = a[orderBy] as any;
        const createdAtB = b[orderBy] as any;
        const [dateComponentsA, timeComponentsA] = createdAtA.split(' - ');
        const [dateComponentsB, timeComponentsB] = createdAtB.split(' - ');
        const [dayA, monthA, yearA] = dateComponentsA.split('/');
        const [dayB, monthB, yearB] = dateComponentsB.split('/');
        const [hoursA, minutesA] = timeComponentsA.split(':');
        const [hoursB, minutesB] = timeComponentsB.split(':');

        const dateA = new Date(+yearA, +monthA - 1, +dayA, +hoursA, +minutesA);
        const dateB = new Date(+yearB, +monthB - 1, +dayB, +hoursB, +minutesB);

        if (dateA.getTime() === dateB.getTime()) {
            return 0;
        }

        return dateA.getTime() < dateB.getTime() ? 1 : -1;
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }

    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

function getComparator<Key extends keyof Track>(order: Order, orderBy: Key): (
    a: { [key in Key]: any },
    b: { [key in Key]: any },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, order)
        : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const TracksAdminTableHead = (props: TracksAdminTableProps) => {
    const { order, orderBy, onRequestSort, headCells } = props;
    const { t } = useTranslation();

    const createSortHandler = (property: keyof Track) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding='normal'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                        {t(headCell.id)}
                        {orderBy === headCell.id &&
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export const TracksAdminTable = (props: {headCells: TracksHeadCell[], tracks: Track[], isLoading: boolean}) => {
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof Track>('votesCount');
    const { headCells, tracks, isLoading } = props;

    const handleRequestSort = (
        event: MouseEvent<unknown>,
        property: keyof Track,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Box sx={{ width: '100%' }} className={isLoading ? 'is-loading' : ''}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <TracksAdminTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(tracks, getComparator(order, orderBy))
                                .map((row: any, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.trackId}>
                                            {headCells.map((headCell) => (
                                                <TableCell key={row.trackId+'-'+headCell.id} align={headCell.align}>
                                                    {headCell.id === 'title' && <p className="c-admin__track-column">{row.title}</p>}
                                                    {headCell.id === 'artist' && <p className="c-admin__track-column">{row.artist}</p>}
                                                    {headCell.id === 'user' && <span title={row.user.name + ' (' + row.user.group + ')'}>{row.user.name}</span>}
                                                    {headCell.id === 'votesCount' && row.votesCount}
                                                    {headCell.id === 'votes' && row.votes.sort((userA: any, userB: any) => (userA.name < userB.name) ? -1 : 1).map((vote: any, key: number) => (
                                                        <p key={key} title={vote.name + ' (' + vote.group + ')'}>{vote.name}</p>
                                                    ))}
                                                    {headCell.id === 'createdAt' && row.createdAt}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

export default TracksAdminTable;