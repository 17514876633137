import { useTranslation } from 'react-i18next';
import masia from '../../assets/icons/icon-masia.svg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import { User } from '../../domain/User/User';
import { useSelector } from 'react-redux';

const Location = () => {
    const { t } = useTranslation();
    const user = useSelector((state:any) => state.auth.user as User);

    return (
        <>
            <section className='c-home__location o-container--full'>
                <div className='o-container'>
                    <h2 className="c-home__title">{t('we marry')}</h2>
                    <div>
                        <CalendarMonthIcon className="c-home__location-icon" />
                        <p>{t('24th september')}</p><br className='u-desktop' />
                    </div>
                    <div>
                        <img src={masia} className="c-home__location-icon" alt={t('mas del sord')} title={t('mas del sord')} />
                        <p>
                            <a
                                href="https://www.google.com/maps/place/Mas+del+Sord+1335/@41.2324,1.08809,15z/data=!4m8!3m7!1s0x0:0x12a910450d013c2a!5m2!4m1!1i2!8m2!3d41.2323959!4d1.088089"
                                title={t('mas del sord')}
                                onClick={(event) => {
                                    event.preventDefault();
                                    window.open('https://www.google.com/maps/place/Mas+del+Sord+1335/@41.2324,1.08809,15z/data=!4m8!3m7!1s0x0:0x12a910450d013c2a!5m2!4m1!1i2!8m2!3d41.2323959!4d1.088089', '_blank');
                                }}
                            >{t('mas del sord')}</a>
                        </p>
                        <p>{t('la selva del camp')}</p>
                    </div>
                    <div>
                        { user?.group?.name !== 'Convidats festa' &&
                            <>
                                <ScheduleIcon className="c-home__location-icon" /> 
                                <p>{t('hour')}</p><br className='u-desktop' />
                            </>
                        }
                        { user?.group?.name === 'Convidats festa' &&
                            <>
                                <NightlifeIcon className="c-home__location-icon" />
                                <p>{t('party')}</p><br className='u-desktop' />
                            </> 
                        }
                    </div>
                </div>
                <div className='c-home__location-image' />
            </section>
        </>
    )
}

export default Location;