import { TracksHeadCell } from "./TracksAdminTable";

export const TracksHead: TracksHeadCell[] = [
    {
        id: 'title',
        align: 'left',
    },
    {
        id: 'artist',
        align: 'left',
    },
    {
        id: 'user',
        align: 'left',
    },
    {
        id: 'votesCount',
        align: 'center',
    },
    {
        id: 'votes',
        align: 'left',
    },
    {
        id: 'createdAt',
        align: 'right',
    }
];

export default TracksHead;