import '../../sass/rsvp.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { User } from '../../domain/User/User';
import { fetchApiUserToSession, refreshAttendees } from '../../actions/user';
import UserService from '../../services/user.service';
import Footer from '../../components/Footer';
import GroupIcon from '@mui/icons-material/Group';
import RsvpForm from '../../components/Rsvp/RsvpForm';
import { Intolerance } from '../../domain/Intolerance/Intolerance';
import IntoleranceService from '../../services/intolerance.service';

const Rsvp = () => {
    const { t } = useTranslation();
    const isLoggedIn = useSelector((state:any) => state.auth.isLoggedIn as boolean);
    const user = useSelector((state:any) => state.auth.user as User);
    const attendees = useSelector((state:any) => state.auth.attendees as number);
    const navigate = useNavigate();

    const [ isLoadingUsers, setLoadingUsers ] = useState<boolean>(true);
    const [ isLoadingAttendees, setLoadingAttendees ] = useState<boolean>(true);
    const [ isLoadingIntolerances, setLoadingIntolerances ] = useState<boolean>(true);
    const [ groupUsers, setGroupUsers ] = useState<User[]>([]);
    const [ intolerances, setIntolerances ] = useState<Intolerance[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoggedIn){
            navigate('/login');
        }

        const isOnlyPartyAttendee = user?.group?.name === 'Convidats festa';
        if (isOnlyPartyAttendee){
            navigate('/music');
        }
    }, [isLoggedIn, navigate, user?.group?.name]);

    useEffect(() => {
        setLoadingAttendees(true);
        dispatch(refreshAttendees() as any)
        .then(() => {
            setLoadingAttendees(false);
        });
    }, [dispatch]);

    useEffect(() => {
        setLoadingIntolerances(true);
        IntoleranceService
            .getIntolerances()
            .then((response) => {
                setIntolerances(response.data);
                setLoadingIntolerances(false);
            });
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            setLoadingUsers(true);
            UserService
                .getGroupUsers(user.group.id)
                .then((response) => {
                    if (response.data.length > 0){
                        setGroupUsers(response.data);
                        response.data.map((groupUser: User) => {
                            if (groupUser.id === user.id){
                                dispatch(
                                    fetchApiUserToSession(groupUser) as any
                                )
                            }

                            return null;
                        });
                    }
                    setLoadingUsers(false);
                });
        }
    }, [user?.group?.id, user?.id, isLoggedIn, dispatch]);

    return (
        <>
            {isLoggedIn &&
                <div className="c-rsvp">
                    <Header />
                    <header className='c-rsvp__header o-container--full'>
                        <div className='o-container'>
                            <h1 className="c-rsvp__title">{t('rsvp')}</h1>
                        </div>
                    </header>
                    <section className={'c-rsvp__content o-container'}>
                        <div className='o-container'>
                            <div className={'c-rsvp__attendees' + (isLoadingUsers ? ' is-loading' : '')}>
                                <GroupIcon className='c-rsvp__attendees-icon' />
                                <h2 className='c-rsvp__attendees-title'>{ t("rsvp counter " + (attendees === 1 ? 'singular' : 'plural')).replace('{quantity}', attendees + '').replace('undefined', '0') }</h2>
                                <p className='c-rsvp__attendees-info'>{ t("rsvp counter info " + (attendees === 1 ? 'singular' : 'plural')) }</p>
                            </div>
                            <div className="c-rsvp__info">
                                { user?.assistance === null && <p>{ t("rsvp not known") }</p> }
                                <p>{ t("fill form") }</p>
                            </div>
                            <div className={"c-rsvp__users" + (isLoadingAttendees || isLoadingIntolerances ? ' is-loading' : '')}>
                                { groupUsers && (
                                    <>
                                    {groupUsers.map((groupUser: User) => {
                                        return <RsvpForm user={groupUser} self={user.id === groupUser.id} intolerances={intolerances} key={groupUser.id} />
                                    })}
                                    </>
                                )}
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Rsvp;