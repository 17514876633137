import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from "react-i18next";
import { Intolerance } from "../../domain/Intolerance/Intolerance";

interface IntoleranceType {
    intolerance: Intolerance,
    self: boolean,
    gender: string,
    checked: boolean,
    onChange: any
}

const InputIntolerance = ({intolerance, self, gender, checked, onChange}: IntoleranceType) => {
    const { t } = useTranslation();
    const label = (intolerance.name === 'Vegetarià' || intolerance.name === 'Vegà') ?
        (
            (self ? t("i'm") : t("is")) + ' ' +
            t(intolerance.name + ' ' + gender)
        ) : t(intolerance.name);

    return (
        <div className="c-rsvp__intolerance">
            <FormControlLabel
                value={intolerance.id}
                control={<Checkbox />}
                label={label}
                className="c-rsvp__intolerance-input"
                name='intolerances'
                checked={checked}
                onChange={onChange}
            />
        </div>
    );
};

export default InputIntolerance;