import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const middleware = [thunk];
const store = configureStore(
    {
        reducer: rootReducer,
        middleware: [...middleware],
        devTools: process.env.ENV !== "production",
    },
    // composeWithDevTools(applyMiddleware(...middleware))
);
    // rootReducer,
    // composeWithDevTools(applyMiddleware(...middleware))

export default store;