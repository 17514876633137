import Button from "../Button";
import Input from "../Input";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../domain/User/User";
import { updateUser } from "../../actions/user";
import { SET_MESSAGE } from "../../actions/types";

export const ProfileForm = () => {
    const { t } = useTranslation();
    const [ isLoading, setLoading ] = useState<boolean>(false);
    const [ phone, setPhone ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');
    const message = useSelector((state: any) => state.message.message as string);
    const user = useSelector((state:any) => state.auth.user as User);
    const dispatch = useDispatch();

    const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    }

    const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        var { phone, email } = document.forms[0];

        if (phone.value !== "" || email.value !== "") {
            setLoading(true);
            dispatch(
                updateUser({
                    phone: phone.value,
                    email: email.value
                }) as any
            )
            .then(() => {
                dispatch({
                    type: SET_MESSAGE,
                    payload: 'thank you',
                });
            })
            .finally(() => {
                setLoading(false);
            });
        }
    };

    return (
        <>
            <p>{ user.gender === 'MALE' ? t("profile modal content male") : t("profile modal content female")}</p>
            {
                message ?
                    <p>{t(message)}</p> : 
                    (<form className={"c-form" + (isLoading ? ' is-loading' : '')} onSubmit={handleSubmit}>
                        <Input id="phone" label={t("phone")} type="text" icon={<PhoneIcon className='c-form__icon' />}  value={phone} onChange={handlePhone} />
                        <Input id="email" label={t("email")} type="text" icon={<EmailIcon className='c-form__icon' />}  value={email} onChange={handleEmail} />
                        <Button type='primary' extraClass="c-form__submit" onClick={() => {}}><SaveIcon /> { t("save") }</Button>
                    </form>)
            }
        </>
    )
}

export default ProfileForm;