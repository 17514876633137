import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import es from "./translations/es.json";
import ca from "./translations/ca.json";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    "es": {
        "translation": es
    },
    "ca": {
        "translation": ca
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        fallbackLng: ["ca", "es"],
        detection: {
            order: ['cookie', 'htmlTag'],
            lookupCookie: 'i18next',
            caches: ['cookie'],
            htmlTag: document.documentElement,
        }
    });

export default i18n;