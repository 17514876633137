import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE, CLEAR_MESSAGE } from "./types";
import AuthService from "../services/auth.service";

export const userSession = () => (dispatch: any) => {
    const user = AuthService.user();

    if (user) {
        dispatch({       
            type: LOGIN_SUCCESS,
            payload: { user },
        });
    }
}
  
export const login = (username: string, password: string) => (dispatch: any) => {
    return AuthService
        .login({username, password})
        .then((data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });

            dispatch({type: CLEAR_MESSAGE});

            return Promise.resolve();
        })
        .catch((error) => {
            const message = (error.response?.data?.message) ||
                    error.message ||
                    error.toString();

            dispatch({type: LOGIN_FAIL});

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        });
};

export const logout = () => (dispatch: any) => {
    dispatch({
        type: LOGOUT,
    });

    AuthService.logout();
};