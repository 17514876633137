import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const LoadingBar = () => {
    const intervalSpeed = 5;
    const incrementSpeed = 0.1;
    const initDate = new Date('2021-06-18T00:00:00+02:00');
    const endDate = new Date('2022-09-24T00:00:00+02:00');
    const today = new Date();
    today.setHours(0,0,0);
    const diffDays = (endDate.getTime() - initDate.getTime()) / (1000 * 60 * 60 * 24);
    const diffToday = (today.getTime() - initDate.getTime()) / (1000 * 60 * 60 * 24);
    const percentEnd = diffToday*100/diffDays;

    const [ percent, setPercent ] = useState<number>(0);
    const [ diff, setDiff] = useState<number>(diffDays);
    const { t } = useTranslation();

    useEffect(() => {
        const progressInterval = setTimeout(function() {
            if (percent >= percentEnd) {
                clearInterval(progressInterval);
            } else {
                setPercent(percent + incrementSpeed);
            }

            setDiff((100-percent)*diffDays/100);
        }, intervalSpeed);
    }, [percent, diffDays, diffToday, percentEnd]);

    return (
        <div className="m-loading--landing">
            <div className="m-loading--landing__dates">
                <span>💍 18/6/21</span>
                <span>24/9/22 🤵👰</span>
            </div>
            <div className="m-loading--landing__bar" id="bar">
                <div className="m-loading--landing__percent" id="percent">{ t('days left').replace('{days}', Math.ceil(diff).toFixed(0))}</div>
                <div className="m-loading--landing__fill" id="fill" style={{width: percent + '%'}}></div>
            </div>
        </div>
    );
}

export default LoadingBar;