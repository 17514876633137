import Button from "../Button";
import Input from "../Input";
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import { useNavigate } from "react-router-dom";

export const LoginForm = () => {
    const { t } = useTranslation();
    const [ isLoading, setLoading ] = useState<boolean>(false);
    const { message } = useSelector((state: any) => state.message);
    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    }

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        var { username, password } = document.forms[0];

        if (username.value !== "" && password.value !== "") {
            setLoading(true);

            dispatch(
                login(username.value.toLowerCase(), password.value) as any
            )
            .then(() => {
                navigate('/');
            })
            .catch(() => {
                setLoading(false);
            });
        }
    };

    return (
        <>
            <form className={"c-form" + (isLoading ? ' is-loading' : '')} onSubmit={handleSubmit}>
                <Input id="username" label={t("username")} type="text" icon={<PersonIcon className='c-form__icon' />} showError={message === "User not found"} errorMessage={t(message)} value={username} onChange={handleUsername} />
                <Input id="password" label={t("password")} type="password" icon={<PasswordIcon className='c-form__icon' />} showError={message === "User invalid password"} errorMessage={t(message)} value={password} onChange={handlePassword} />
                <Button type='primary' extraClass="c-form__submit"><LoginIcon /> { t("login") }</Button>
            </form>
        </>
    )
}

export default LoginForm;