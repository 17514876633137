import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, UPDATE_USER, REFRESH_USER, REFRESH_ATTENDEES, ADD_VOTE_USER, REMOVE_VOTE_USER } from "../actions/types";
import AuthService from "../services/auth.service";

const user = AuthService.user();
const initialState = user
    ? { isLoggedIn: true, user, attendees: 0 }
    : { isLoggedIn: false, user: null };

export default function Auth(state = initialState, action: any) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                showUserModal: payload.user?.phone === null && payload.user?.email === null
            };
        case UPDATE_USER:
            return {
                ...state,
                user: payload.user,
                showUserModal: false
            };
        case LOGIN_FAIL:
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case REFRESH_USER:
            return {
                ...state,
                user: payload.user,
            }
        case REFRESH_ATTENDEES:
            return {
                ...state,
                attendees: payload.attendees,
            }
        case ADD_VOTE_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    votes: [...state.user.votes, payload.trackId]
                }
            }
        case REMOVE_VOTE_USER:
            const votes = state.user.votes as Array<string>;
            return {
                ...state,
                user: {
                    ...state.user,
                    votes: votes.filter(id => id !== payload.trackId)
                }
            }
        default:
            return state;
    }
}