import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { User } from "../../domain/User/User";
import { Modal, ModalType } from "../Modal";
import ProfileForm from "./ProfileForm";

const ProfileModal = ({isModalVisible, showModal}: ModalType) => {
    const { t } = useTranslation();
    const user = useSelector((state:any) => state.auth.user as User);

    return (
        <Modal
            isModalVisible={isModalVisible}
            showModal={showModal}
            title={t("profile modal title").replace("{name}", user.name)}
            body={<ProfileForm />}
        />
    );
};

export default ProfileModal;