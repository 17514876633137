import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { userSession } from './actions/auth';
import Rsvp from './pages/Rsvp/Rsvp';
import Home from './pages/Home/Home';
import Landing from './pages/Landing/Landing';
import ScrollToTop from './hooks/scroll-to-top';
import Admin from './pages/Admin/Admin';
import Music from './pages/Music/Music';
import Hacker from './pages/Hacker/Hacker';

function App() {
    const { i18n } = useTranslation();
    
    const dispatch = useDispatch();
    dispatch(userSession() as any);

    document.documentElement.lang = i18n.language;

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/login" element={<Landing />}/>
                <Route path="/" element={<Home />}/>
                <Route path="/rsvp" element={<Rsvp />}/>
                <Route path="/music" element={<Music />}/>
                <Route path="/hacker" element={<Hacker />}/>
                <Route path="/admin/:section" element={<Admin />}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
