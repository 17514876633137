import { ReactElement } from "react";
import { Modal as MuiModal } from '@mui/material';

export interface ModalType {
    isModalVisible: boolean,
    showModal: Function,
    title?: string,
    body?: ReactElement,
    footer?: ReactElement,
    extraClass?: string
}

export const Modal = ({isModalVisible, showModal, title, body, footer, extraClass = ""}: ModalType) => {
    return (
        <MuiModal
            open={isModalVisible}
            onClose={() => showModal(false)}
            className={"c-modal" + (extraClass ? " " + extraClass : "")}
        >
            <div className="c-modal__content">
                { title && 
                    <div className="c-modal__header">
                        <h2 className="c-modal__title">{ title }</h2>
                    </div>
                }
                <span className='c-modal__close' onClick={() => showModal(false)}>&times;</span>
                { body && <div className="c-modal__body">{ body }</div> }
                { footer && <div className="c-modal__footer">{ footer }</div> }
            </div>
        </MuiModal>
    );
}

export default Modal;