import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AdminHeader from './AdminHeader';
import MusicService from '../../services/music.service';
import TracksAdminTable from './TracksAdminTable';
import TracksHead from './TracksHead';
import { Track } from '../../domain/Music/Track';

const Tracks = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [tracks, setTracks] = useState<Track[]>([]);

    const totalVotes = (tracks: Track[]) => {
        return tracks.reduce((acc, track) => {
            return acc + track.votesCount;
        }, 0);
    }

    useEffect(() => {
        MusicService
        .getTracks({
            limit: 500
        })
        .then((response) => {
            setTracks(response.data.tracks);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    return (
        <>
            <AdminHeader section={ t('music') } />
            <section className='c-admin__content o-container--full'>
                <div className='c-admin__users'>
                    <h2 className="c-admin__subtitle"><PlaylistAddCheckIcon className='c-admin__subtitle-icon' />{t('music')} ({tracks.length} {t('songs')} / {totalVotes(tracks)} {t('votesCount').toLowerCase()})</h2>
                    <TracksAdminTable headCells={TracksHead} tracks={tracks} isLoading={loading} />
                </div>
            </section>
        </>
    );
}

export default Tracks;