import '../../sass/landing.scss';
import logo from '../../assets/logos/logo.png';
import { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from '../../components/ChangeLanguage';
import LoadingBar from '../../components/Landing/LoadingBar';
import LoginModal from '../../components/Landing/LoginModal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Divider from '../../components/Divider';

const Landing = () => {
    const { t } = useTranslation();
    const [ openLogin, setOpenLogin ] = useState<boolean>(false);
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state:any) => state.auth.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn){
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    return (
        <>
            <div className="c-landing">
                <ChangeLanguage />
                <section className="c-landing__container">
                    <main className="c-landing__left">
                        <div className="c-landing__left-container">
                            <div className="m-logo--landing">
                                <img src={logo} alt="logo" className="m-logo__image" />
                            </div>
                            <LoadingBar />
                            <div className="c-landing__login">
                                <Button type={'primary'} extraClass={"c-landing__login--button"} onClick={() => setOpenLogin(true)}>{ t("login") }</Button>
                            </div>
                        </div>
                    </main>
                    <aside className="c-landing__right">
                        <Divider extraClass='c-landing__right-divider'/>
                    </aside>
                </section>
            </div>
            <LoginModal
                isModalVisible={openLogin}
                showModal={setOpenLogin}
            />
        </>
    )
}

export default Landing;