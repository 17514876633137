import { Track } from "../../domain/Music/Track";
import playIcon from '../../assets/icons/icon-play.svg';
import Button from "../Button";
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from "react-i18next";

interface RankingTrackType {
    track: Track,
    votingTrack: string,
    userVoted: boolean,
    ranking: number,
    vote: Function,
    play: Function,
}

const RankingTrack = ({track, votingTrack, userVoted, ranking, vote, play}: RankingTrackType) => {
    const { t } = useTranslation();
    const isLoading = votingTrack === track.trackId;

    return (
        <>
            <div className='c-ranking__track' key={track.trackId}>
                <div>
                    <span className={"c-ranking__number" + (ranking >= 100 ? ' is-small' : '')}>{('00'+(ranking)).slice((ranking >= 100 ? -3 : -2))}</span>
                    <Button extraClass={'c-music__track--vote' + (isLoading ? ' is-disabled is-loading' : '')} onClick={() => {vote(track)}}>{ userVoted ? <CheckIcon className="c-ranking__voted" /> : (isLoading ? '' : t('vote')) }</Button>
                </div>
                <div className="c-ranking__song">
                    <div className="c-ranking__album" onClick={() => play(track)}>
                        {track.images?.length && <img className='c-ranking__portrait' src={track.images.find(image => image.width === 300)?.url} alt={track.title} title={track.title} />}
                        {track.preview && 
                            <>
                                <div className="c-ranking__play-overlay"></div>
                                <div className="c-ranking__play-icon">
                                    <img src={playIcon} alt={t("play")} title={t("play")} />
                                </div>
                            </>
                        }
                    </div>
                    <div>
                        <h3 className="c-ranking__song-title">
                            <button type="button" className={!track.preview ? 'no-preview' : ''} onClick={() => play(track)}>{track.title}</button>
                        </h3>
                        <p className="c-ranking__song-artist">{track.artist}</p>
                    </div>
                </div>
                <span className="c-ranking__votes"><StarIcon className='c-ranking__votes-icon' /> <span>{track.votesCount}</span></span>
            </div>
        </>
    );
}

export default RankingTrack;