import { ReactElement, useState } from "react";

export interface InputType {
    id: string,
    name?: string,
    label: string,
    type: string,
    showError?: boolean,
    errorMessage?: string,
    icon?: ReactElement,
    extraClass?: string,
    value?: string,
    onChange?: Function
}

export const Input = ({id, name, label, type, icon, onChange, value = "", showError = false, errorMessage = "", extraClass = ""}: InputType) => {
    const [focus, setFocus] = useState<boolean>(false);
    const [empty, setEmpty] = useState<boolean>(value.length === 0);

    return (
        <>
            <div className={"c-form__container" + (extraClass ? " " + extraClass : "") + (icon ? ' has-icon' : '')}>
                {icon && icon}
                <input
                    className={'c-form__input' + (focus ? ' c-form__input--active' : '')}
                    type={type}
                    id={id}
                    name={name ? name : id}
                    value={value}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    onChange={(e) => {
                        setEmpty(e.target.value.length === 0)
                        if (onChange) onChange(e);
                    }}
                />
                <label className={'c-form__label' + (focus || !empty ? ' c-form__label--active' : '')} htmlFor={id}>{ label }</label>

                {showError && <div className="c-form__error">{errorMessage}</div>}
            </div>
        </>
    );
}

export default Input;