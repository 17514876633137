import { REFRESH_ATTENDEES, REFRESH_USER, UPDATE_USER, ADD_VOTE_USER, REMOVE_VOTE_USER } from "./types";
import AuthService from "../services/auth.service";
import UserService, { UpdateUserDTO } from "../services/user.service";
import { Buffer } from 'buffer';
import { User } from "../domain/User/User";
import MusicService from "../services/music.service";

export const updateUser = (data: UpdateUserDTO) => (dispatch: any) => {
    const sessionUser = AuthService.user();

    if (sessionUser) {
        return UserService
            .updateUser(sessionUser.id, data)
            .then((response) => {
                dispatch({
                    type: UPDATE_USER,
                    payload: { user: response.data },
                });
                const user = Buffer.from(JSON.stringify(response.data), 'utf8').toString('base64');
                sessionStorage.setItem("user", user);

                return Promise.resolve();
            }
        );
    }
}

export const confirmAssistance = (data: UpdateUserDTO) => async (dispatch: any) => {
    const sessionUser = AuthService.user();
    const response = await UserService.confirmAssistance(data);

    if (sessionUser.id === data.user) {
        dispatch({
            type: UPDATE_USER,
            payload: { user: response.data },
        });
    }

    return await Promise.resolve();
}

export const refreshAttendees = () => async (dispatch: any) => {
    const response = await UserService.getAttendees(true);
    
    dispatch({
        type: REFRESH_ATTENDEES,
        payload: { attendees: response.data },
    });
}

export const fetchApiUserToSession = (data: User) => (dispatch: any) => {
    dispatch({
        type: REFRESH_USER,
        payload: { user: data },
    });
    const user = Buffer.from(JSON.stringify(data), 'utf8').toString('base64');
    sessionStorage.setItem("user", user);
}

export const voteTrack = (trackId: string, user: User) => async (dispatch: any) => {
    const response = await MusicService.voteTrack(trackId, user.id);

    dispatch({
        type: ADD_VOTE_USER,
        payload: { trackId: response.data.trackId },
    });

    user.votes = [...user.votes, response.data.trackId];
    const sessionUser = Buffer.from(JSON.stringify(user), 'utf8').toString('base64');
    sessionStorage.setItem("user", sessionUser);

    return await Promise.resolve();
}

export const deleteVoteTrack = (trackId: string, user: User) => async (dispatch: any) => {
    await MusicService.deleteVoteTrack(trackId, user.id);

    dispatch({
        type: REMOVE_VOTE_USER,
        payload: { trackId: trackId },
    });

    user.votes = user.votes.filter(id => id !== trackId);
    const sessionUser = Buffer.from(JSON.stringify(user), 'utf8').toString('base64');
    sessionStorage.setItem("user", sessionUser);

    return await Promise.resolve();
}
