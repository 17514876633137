interface DividerType {
    extraClass?: string,
    color?: string,
    position?: "top"|"bottom"
}

export const Divider = ({color = "white", extraClass = '', position = 'bottom'}: DividerType) => {
    let points = "0,100 1,100 100,0 0,0";

    return <svg className={"m-divider m-divider--" + position + ( extraClass ? ' ' + extraClass : '' )} fill={color} viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points={points}></polygon>
    </svg>
}

export default Divider;