import axios from "axios";
import { Buffer } from 'buffer';

const API_URL = process.env.REACT_APP_API_URL;

export interface LoginUserDTO {
    username: string;
    password: string;
}

const login = async (data: LoginUserDTO) => {
    const params = new URLSearchParams();
    params.append('username', data.username);
    params.append('password', data.password);
    
    const response = await axios.post(API_URL + "/login", params);
    
    if (response.data) {
        const user = Buffer.from(JSON.stringify(response.data), 'utf8').toString('base64');
        sessionStorage.setItem("user", user);
    }

    return response.data;
};

const user = () => {
    const user = sessionStorage.getItem("user") || '';

    if (user) {
        return JSON.parse(Buffer.from(user, 'base64').toString());
    }
    
    return null;
}

const logout = () => {
    sessionStorage.removeItem("user");
};

const AuthService = {
    user,
    login,
    logout
};

export default AuthService;